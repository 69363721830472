.item {
  // margin: auto;
  margin-top: 12px;
  padding: 7px 18px !important;
  cursor: pointer;
  border: 1px solid lightgray;
  border-radius: 0 !important;
  background: #fff;
  box-shadow: none;

  &.isChanged {
    border-width: 1px;
    border-style: dashed;
  }

  &.isMoved {
    border: 1px dashed #5ab5e8;
  }

  &__draggable {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 23px 18px 0 0;
    background: transparent url("../../images/draggable.svg") center no-repeat;
    background-size: 18px 9px;

    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
}

.itemContainer {
  display: flex;

  min-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.itemImage::before {
  display: block;

  content: "";
}

.itemImage::after {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  content: "" "";

  /* stylelint-disable-next-line function-url-quotes */
  background: no-repeat white;
}

.item {
  margin: 12px 15px;
  padding: 7px 18px !important;

  cursor: pointer;

  border: 1px solid lightgray;
  border-radius: 4px;
  background: #fff;
  box-shadow: none;

  &__draggable {
    /* grid-area: draggable; */
    width: 20px;
    height: 20px;
    margin: 23px 18px 0 0;

    cursor: pointer;

    background: transparent url("../../images/draggable.svg") center no-repeat;
    background-size: 18px 9px;

    &:active {
      cursor: grabbing;
    }

    @media (max-width: 900px) {
      display: none;
    }
  }
}

.itemContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  min-width: 100%;
}

.id,
.text {
  margin-top: 4px;

  color: #919191;

  font-size: 14px;
  line-height: 16px;
}

.text {
  margin-top: 10px;

  a {
    color: #588ca3;
    text-decoration-line: underline;
  }

  &:hover {
    a {
      text-decoration: none;
    }
  }
}

.id {
  display: block;

  margin-top: 4px;
}

.dBlock {
  display: block;
}

.dInlineBlock {
  display: inline-block;
}

.top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.version {
  margin-right: 50px;

  color: #8c8c8c;

  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}

.description {
  max-width: 546px;
  margin-top: 6px;

  color: #454545;

  font-size: 14px;
  line-height: 16px;
}

.detailedTitle {
  display: block;

  margin-top: 20px;

  font-weight: 700;
}

.button {
  display: block !important;

  width: 100px !important;
  margin: 14px 0 0 !important;
  padding: 10px 0 !important;

  border-radius: 0 !important;
}

.button.add {
  color: #57d983 !important;
  border: 1px solid #57d983 !important;
  background: none !important;

  &:hover {
    color: white !important;
    background: #57d983 !important;
  }

  &:active {
    color: white !important;
    background: #47b36b !important;
  }
}

.button.remove {
  color: #d9304f !important;
  border: 1px solid #d9304f !important;
  background: none !important;

  &:hover {
    color: white !important;
    background: #d9304f !important;
  }

  &:active {
    color: white !important;
    background: #c42441 !important;
  }
}

.title {
  display: flex;

  margin: 0;

  color: #2a2a2a !important;

  font-family: Montserrat, sans-serif;
  font-size: 16px !important;
  font-weight: 900;
  font-style: normal;
  line-height: 149% !important;
  grid-template-columns: max-content 1fr;
}

.titleUsers {
  display: flex;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  text-align: center;
  align-self: start;
  width: 25px;
  height: 20px;
  color: #fff;
  border-radius: 4px;
  background: #588ca3;
  font-family: Roboto;
  font-size: 10px;
}

.order {
  display: flex;
  align-items: center;

  margin-right: 10px;
  margin-bottom: 2px !important;

  color: #5ab5e8;

  font-size: 14px;
  font-weight: 400;
  line-height: 149%;

  svg {
    margin-right: 2px;
  }
}

.down {
  transform: rotate(180deg);
}

.itemContainer {
  display: flex;

  min-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.item {
  padding: 7px 18px !important;
  margin-top: 12px;

  cursor: pointer;

  border: 1px solid lightgray;
  border-radius: 4px;
  background: #fff;
  box-shadow: none;

  &.isChanged {
    border-width: 2px;
    border-style: dashed;
  }
}

.loaderContainer {
  margin: auto;
  width: 50%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

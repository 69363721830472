* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ui.basic.button,
.ui.basic.buttons .message-button {
  color: #fff !important;
  box-shadow: 0 0 0 1px #fff inset;
}

.ui[class*="left icon"].input > .app-input {
  padding-right: 3em !important;
}

.ui.large.fluid.left.icon.input.Input_input__3rPb3 > button {
  margin-right: 12px;
}

.ui.large.fluid.left.icon.input.Input_input__3rPb3 > i {
  margin-left: 12px;
}

.clearInput {
  margin: 0 10px !important;

  padding: 8px !important;

  cursor: pointer;

  transform: translateY(-50%);

  border: none;
  background: none !important;
  box-shadow: none !important;

  &:hover {
    background: none !important;
    box-shadow: none !important;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    &::before,
    &::after {
      position: absolute;

      bottom: -3px;

      width: 70%;
      height: 2px;

      content: "";

      background-color: #980707;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.infoTitle {
  text-align: left !important;
  letter-spacing: 0 !important;

  color: #2a2a2a !important;

  font-family: Montserrat, sans-serif !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  font-style: normal !important;
  line-height: 39px !important;

  &.link {
    cursor: pointer;

    &:hover {
      color: #686868 !important;
    }

    &:active {
      color: #0a0a0a !important;
    }
  }
}

h2.infoTitle {
  font-size: 26px !important;
  font-weight: 700 !important;
}

.small-link {
  margin-left: 2rem;

  cursor: pointer;

  color: #2a2a2a;
  border: none;
  background: none;

  font-family: Montserrat, sans-serif;
  font-weight: 500;

  &:hover {
    color: #686868;
  }

  &:active {
    color: #0a0a0a;
  }
}

.input {
	position: relative;

	input {
		padding-right: 30px !important;
	}
}

.clearInput {
	position: absolute;
	top: 50%;
	right: 20px;

	padding: 8px !important;

	transform: translateY(-50%);

	opacity: 0;

	border-radius: 0;

  &:hover {
    box-shadow: none !important;
  }

	span {
		position: absolute;
		top: 0;
		left: 0;

		display: block;

		width: 100%;
		height: 100%;

		&::before,
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;

			width: 100%;
			height: 2px;

			content: '';

			background-color: #2A2A2A;
		}

		&::before {
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
}

.show {
	opacity: 1;
}

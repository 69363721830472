.item {
	margin: 12px 15px;
	padding: 7px 18px !important;

	cursor: grabbing;

	border: 1px solid lightgray;
  border-radius: 0 !important;
	background: #FFF;
  box-shadow: 0px 12px 19px lightgray;

  &__draggable {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin: 23px 18px 0 0;
      background: transparent url('../../images/draggable.svg')
          center no-repeat;
      background-size: 18px 9px;

      &:active {
        cursor: grabbing;
      }
  }
}

.itemContainer {
	display: flex;

	min-width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
